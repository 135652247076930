import Vue from 'vue';
import Router from 'vue-router';
import store from '../store'
import axios from 'axios';
import CONSTANTES from '../Constantes.js'

// Layouts
const ContentMasterLayout = () =>
    import ('@/Layout/Master/ContentLayout')
const ContentCoordinadorLayout = () =>
    import ('@/Layout/Coordinador/ContentLayout')
const ContentCoordinadorCuestionariosLayout = () =>
    import ('@/Layout/Coordinador/Cuestionarios/ContentLayout')

//middlewates
Vue.use(Router)

function checkPermissionsModule(to, from, next) {
    if (to.matched[1].meta.module.ISOname) {
        if (!!store.state.modules.find(x => x.identificador == to.matched[1].meta.module.ISOname)) {
            if (store.state.permissions.filter(x => x.codigo == to.matched[1].meta.module.name)[0].r == 1) {
                next();
            } else {
                next({
                    name: 'Panel de Control'
                });
                // console.log(`No puedes estar aqui modulo ${to.matched[1].meta.module.name} y perteneces a la norma ${to.matched[1].meta.module.ISOname}`)
            }
        } else {
            next({
                name: 'Panel de Control'
            });
            // console.log(`No puedes estar aqui modulo ${to.matched[1].meta.module.ISOname} necesitas el plan de la norma`)
        }
    } else {
        if (store.state.permissions.filter(x => x.codigo == to.matched[1].meta.module.name)[0].r == 1) {
            next();
        } else {
            next({
                name: 'Panel de Control'
            });
            // console.log(`No puedes estar aqui modulo ${to.matched[1].meta.module.name}`)
        }
    }
}

function checkPermissionsSubModule(to, from, next) {
    if (to.matched[2].meta.module.extend) {
        if (store.state.permissions.filter(x => x.codigo == to.matched[2].meta.module.extend)[0].r == 1) {
            if (store.state.permissions.filter(x => x.codigo == to.matched[2].meta.module.name)[0].r == 1) {
                next();
            } else {
                next({
                    name: 'Panel de Control'
                });
                // console.log(`no puedes estar aqui submodulo ${to.matched[2].meta.module.name} tienes padre`)
            }
        } else {
            next({
                name: 'Panel de Control'
            });
            // console.log(`no puedes estar aqui submodulo ${to.matched[2].meta.module.name} el modulo padre ${to.matched[2].meta.module.extend} te bloquea `)
        }

    } else {
        if (store.state.permissions.filter(x => x.codigo == to.matched[2].meta.module.name)[0].r == 1) {
            next();
        } else {
            next({
                name: 'Panel de Control'
            });
            // console.log(`no puedes estar aqui submodulo ${to.matched[2].meta.module.name}`)
        }
    }

}



const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
})

router.beforeEach((to, from, next) => {
    if (!to.fullPath.includes("login") && !to.fullPath.includes("recuperar") && !to.fullPath.includes("cuestionarios") && to.name != '404') {
        if (localStorage.getItem('token')) {
            // Vue.swal.mixin({
            //     toast: true,
            //     showConfirmButton: false,
            //     position: 'bottom-right',
            //     timer: 3500,
            //     timerProgressBar: true,
            //     didOpen: (toast) => {
            //         toast.addEventListener('mouseenter', Vue.swal.stopTimer)
            //         toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
            //     }
            // }).fire({
            //     icon: 'info',
            //     title: 'Cargando...'
            // });
            axios({
                method: 'post',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                url: CONSTANTES.URL_RUTA_SERVICIOS + 'auth/valida-token'
            }).then(response => {
                store.commit('SET_USER_DATA', response.data.user);
                store.commit('SET_MODULES_DATA', response.data.modules);
                store.commit('SET_PERMISSIONS_DATA', response.data.permissions);
                if (to.meta.isTipo) {
                    if (response.data.user.uidType == to.meta.isTipo) {
                        next();
                    } else {
                        router.push({ name: 'Pagina404' });
                    }
                } else {
                    next();
                }
            }).catch(function(error) {
                localStorage.clear();
                router.push({ name: 'Pagina404' });
            });
        } else {
            localStorage.clear();
            router.push({ name: 'Login' });
        }
    } else {
        next();
    }
});

function configRoutes() {
    return [{
            path: '/',
            redirect: '/master/panel-principal',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '/',
                    name: 'router page',
                    component: () =>
                        import ('@/views/Paginas/RouterPage.vue')
                },
                {
                    path: 'master',
                    redirect: '/master/panel-principal',
                    meta: { isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4", label: "Inicio" },
                    component: ContentMasterLayout,
                    children: [{
                            path: 'panel-principal',
                            name: 'Panel Principal master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: "Panel Principal"
                            },
                            component: () =>
                                import ('@/views/Master/PanelPrincipal.vue'),
                        },
                        {
                            path: 'perfil',
                            name: 'Perfil master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Mi Perfil'
                            },
                            component: () =>
                                import ('@/views/Paginas/PerfilMaster.vue')
                        },
                        {
                            path: 'posibles-clientes',
                            name: 'Posibles Clientes master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Posibles clientes'
                            },
                            component: () =>
                                import ('@/views/Master/PosiblesClientes.vue')
                        },

                        {
                            path: 'clientes',
                            name: 'Clientes master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Clientes'
                            },
                            component: () =>
                                import ('@/views/Master/Clientes.vue')
                        },
                        {
                            path: 'gestion-cliente/:id',
                            name: 'Gestion del Cliente master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Gestión del cliente'
                            },
                            component: () =>
                                import ('@/views/Master/GestionCliente.vue')
                        }, {
                            path: 'documentos-comerciales',
                            name: 'Documentos comerciales master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Documentos comerciales'
                            },
                            component: () =>
                                import ('@/views/Master/DocumentosComerciales.vue')
                        },
                        {
                            path: 'archivos/:id/:nombre',
                            name: 'archivos master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Archivos de la carpeta'
                            },
                            component: () =>
                                import ('@/views/Master/VerDocumentos.vue')
                        },
                        {
                            path: 'colaboradores',
                            name: 'Colaboradores master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Colaboradores'
                            },
                            component: () =>
                                import ('@/views/Master/Colaboradores.vue')
                        },
                        {
                            path: 'ofertas',
                            name: 'Ofertas master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Ofertas'
                            },
                            component: () =>
                                import ('@/views/Master/Ofertas.vue')
                        }, {
                            path: 'gestion-oferta/:id?',
                            name: 'Gestión de oferta master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Gestión oferta'
                            },
                            component: () =>
                                import ('@/views/Master/GestionOferta.vue')
                        },
                        {
                            path: 'ventas',
                            name: 'Ventas master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Ventas'
                            },
                            component: () =>
                                import ('@/views/Master/Ventas.vue')
                        },
                        {
                            path: 'gestion-venta/:id?',
                            name: 'Gestión de venta master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Gestión venta'
                            },
                            component: () =>
                                import ('@/views/Master/GestionVenta.vue')
                        },
                        {
                            path: 'incidencias-de-software',
                            name: 'Incidencias de software master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Gestión incidencias de software'
                            },
                            component: () =>
                                import ('@/views/Master/IncidenciasSoftware.vue')
                        },
                        {
                            path: 'servicios',
                            name: 'Servicios master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Servicios'
                            },
                            component: () =>
                                import ('@/views/Master/Servicios.vue')
                        },
                        {
                            path: 'perfiles',
                            name: 'Perfiles master',
                            meta: {
                                isTipo: "4b450506-0380-4a54-b05a-ba623fe749a4",
                                label: 'Perfiles'
                            },
                            component: () =>
                                import ('@/views/Master/Perfiles.vue')
                        },

                    ]
                }

            ]
        },
        {
            path: '',
            redirect: '/panel-de-control',
            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Inicio" },
            component: ContentCoordinadorLayout,
            children: [{
                    path: 'documentacion',
                    redirect: '/documentacion/cargar-descargar-documentos',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Documentación", module: { name: "001-DOC" } },
                    name: 'Documentacion',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'cargar-descargar-documentos',
                        name: 'Cargar y descargar',
                        beforeEnter: checkPermissionsSubModule,
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Cargar y descargar", module: { name: "001-DOC-DEC" } },
                        component: () =>
                            import ('@/views/Coordinador/Documentacion/CargarDescargarDoc.vue'),
                    }, {
                        path: 'banco-normas',
                        name: 'Banco de normas',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Banco de normas", module: { name: "001-DOC-BAN" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Documentacion/BancoNormas.vue'),
                    }, {
                        path: 'etiquetas',
                        name: 'Etiquetas',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Etiquetas", module: { name: "001-DOC-BAN-ETI", extend: "001-DOC-BAN" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Documentacion/Etiquetas.vue'),
                    }, {
                        path: 'buscar-por-etiquetas',
                        name: 'Buscar por etiquetas',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Buscar por etiquetas", module: { name: "001-DOC-BAN" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Documentacion/BuscarPorEtiquetas.vue'),
                    }, {
                        path: 'ver-documentos-coordinador/:id/:name',
                        name: 'Ver documentos coordinador',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Ver documentos coordinador", module: { name: "001-DOC-BAN-DOC", extend: "001-DOC-BAN" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Documentacion/VerDocumentos.vue'),
                    }]
                }, {
                    path: 'procesos-y-objetivos',
                    redirect: '/procesos-y-objetivos/mapa-de-procesos',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Procesos y objetivos", module: { name: "002-PRO" } },
                    name: "Procesos y objetivos",
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'mapa-de-procesos',
                            name: 'Mapa de procesos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Mapa de procesos", module: { name: "002-PRO-MAP" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/ProcesosObjetivos/MapaProcesos.vue'),
                        },
                        {
                            path: 'indicador-proceso/:id',
                            name: 'Indicadores del proceso',
                            meta: {
                                isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                                label: "Indicadores del proceso",
                                module: { name: "002-PRO-MAP" }
                            },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/ProcesosObjetivos/IndicadoresProceso.vue'),
                        },
                        {
                            path: 'objetivos',
                            name: 'Objetivos',
                            meta: {
                                isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                                label: "Objetivos",
                                module: { name: "002-PRO-OBJ" }
                            },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/ProcesosObjetivos/Objetivos.vue'),
                        },
                        {
                            path: 'indicadores',
                            name: 'Indicadores',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Indicadores", module: { name: "002-PRO-IND" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/ProcesosObjetivos/Indicadores.vue'),
                        },

                        {
                            path: 'gestion-de-objetivos/:id?/',
                            name: 'Gestión de objetivos',
                            beforeEnter: checkPermissionsSubModule,
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de objetivos", module: { name: "002-PRO-OBJ" } },
                            component: () =>
                                import ('@/views/Coordinador/ProcesosObjetivos/GestionObjetivos.vue'),
                        },
                    ]
                }, {
                    path: 'riesgos',
                    redirect: '/riesgos/foda',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Riesgos", module: { name: '003-RIE' } },
                    name: "Riesgos",
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'foda',
                            name: 'FODA',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "FODA", module: { name: '003-RIE-FOD' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/Foda.vue'),
                        },
                        {
                            path: 'estrategias-foda/:id',
                            name: 'Estrategias FODA',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Estrategias FODA", module: { name: '003-RIE-FOD' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/EstrategiasFoda.vue'),
                        },
                        {
                            path: 'contexto',
                            name: 'Contexto',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Contexto", module: { name: '003-RIE-CON' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/Contexto.vue'),
                        }, {
                            path: 'partes-interesadas',
                            name: 'Partes Interesadas',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Partes Interesadas", module: { name: '003-RIE-CON-PAR', extend: '003-RIE-CON' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/PartesInteresadas.vue'),
                        },
                        {
                            path: 'informes',
                            name: 'Informes',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Informes", module: { name: '003-RIE-INF' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/Informes.vue'),
                        },
                        {
                            path: 'gestion-de-riesgos/:id?',
                            name: 'Gestión de riesgos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de riesgos", module: { name: '003-RIE-GES' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/GestionRiesgos.vue'),
                        },
                        {
                            path: 'evaluacion-de-riesgos',
                            name: 'Evaluación de riesgos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Evaluación de riesgos", module: { name: '003-RIE-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/EvaluacionRiesgos.vue'),
                        },
                        {
                            path: 'seleccion-riesgos/:id?',
                            name: 'Selección riesgos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Selección riesgos", module: { name: '003-RIE-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/SeleccionRiesgos.vue'),
                        },
                        {
                            path: 'gestion-evaluacion-riesgos/:id?',
                            name: 'Gestión evaluación riesgos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión evaluación riesgos", module: { name: '003-RIE-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/GestionEvaluacionRiesgos.vue'),
                        },
                        {
                            path: 'generar-grafico-evaluacion/:id?',
                            name: 'Generar gráfico evaluación',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Generar gráfico evaluación", module: { name: '003-RIE-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/GenerarGraficoEvaluacion.vue'),
                        },
                        {
                            path: 'riesgos',
                            name: 'Riesgos de evaluación',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Riesgos de evaluación", module: { name: '003-RIE-EVA-RIE', extend: '003-RIE-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Riesgos/Riesgos.vue'),
                        }
                    ]
                },
                {
                    path: 'empresa-segura',
                    redirect: '/empresa-segura/gestion-inicial',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Empresa segura", module: { name: '016-VCM', ISOname: 'VCM' } },
                    name: "Empresa segura",
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'gestion-inicial',
                            name: 'Gestión inicial empresa segura',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión inicial", module: { name: '016-VCM-GEI' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/EmpresaSegura/GestionInicial.vue'),
                        },
                        {
                            path: 'gestion-inicial-informe/:id?',
                            name: 'Gestión inicial empresa segura informe',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión inicial informe", module: { name: '016-VCM-GEI' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/EmpresaSegura/GestionInicialInformes.vue'),
                        },
                        {
                            path: 'documentacion',
                            name: 'Gestión inicial empresa segura documentos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Documentación", module: { name: '016-VCM-GEI-DOC', extend: '016-VCM-GEI' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/EmpresaSegura/GestionInicialDocumentacion.vue'),
                        },
                        {
                            path: 'informe-a-la-comunidad',
                            name: 'Informe a la comunidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Informe a la comunidad", module: { name: '016-VCM-INC' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/EmpresaSegura/InformesComunidad.vue'),
                        },
                        {
                            path: 'comunidad',
                            name: 'Comunidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Comunidad", module: { name: '016-VCM-INC-COM', extend: '016-VCM-INC' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/EmpresaSegura/Comunidad.vue'),
                        },
                        {
                            path: 'videos-de-sensibilizacion',
                            name: 'Videos sensiblización',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Videos de sensibilización", module: { name: '016-VCM-VID' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/EmpresaSegura/VideoSensibilizacion.vue'),
                        },
                    ]
                },
                {
                    path: 'gestion-medioambiental',
                    redirect: '/gestion-medioambiental/evaluacion',
                    meta: {
                        label: "ISO 14001 - Gestión Medioambiental",
                        isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                        module: {
                            name: "004-14001",
                            ISOname: 'SGMA'
                        }
                    },
                    name: 'ISO 14001',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'evaluacion',
                            name: 'Evaluación 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Evaluación", module: { name: "004-14001-EVA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Evaluacion.vue'),
                        },
                        {
                            path: 'seleccion-aspectos/:id?',
                            name: 'Selección aspectos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Selección de aspectos", module: { name: "004-14001-EVA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/SeleccionAspectos.vue'),
                        },
                        {
                            path: 'gestion-evaluacion-aspectos/:id?',
                            name: 'Gestión evaluación aspectos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión evaluación de aspectos", module: { name: "004-14001-EVA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/GestionEvaluacionAspectos.vue'),
                        },
                        {
                            path: 'generar-grafico-evaluacion/:id?',
                            name: 'Generar gráfico evaluación de aspectos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Generar gráfico evaluación de aspectos", module: { name: "004-14001-EVA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/GenerarGraficoEvaluacion.vue'),
                        },
                        {
                            path: 'aspectos',
                            name: 'Aspectos 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Aspectos", module: { name: "004-14001-EVA-ASP", extend: '004-14001-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Aspectos.vue'),
                        }, {
                            path: 'procesos',
                            name: 'Procesos 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Procesos", module: { name: "004-14001-EVA-PRO", extend: '004-14001-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Procesos.vue'),
                        }, {
                            path: 'actividades',
                            name: 'Actividades 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Actividades", module: { name: "004-14001-EVA-ACT", extend: '004-14001-EVA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Actividades.vue'),
                        },
                        {
                            path: 'gestiones/:id?',
                            name: 'Gestiones 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestiones", module: { name: "004-14001-GES" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Gestiones.vue'),
                        },
                        {
                            path: 'informes',
                            name: 'Informes 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Informes", module: { name: "004-14001-INF" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Informes.vue'),
                        }, {
                            path: 'requisitos-mediambientales',
                            name: 'Requisitos medioambientales 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Requisitos medioambientales", module: { name: "004-14001-REA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/RequisitosMedioambientales.vue'),
                        },
                        {
                            path: 'seleccion-requisitos/:id?',
                            name: 'Selección requisitos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Selección de requisitos", module: { name: "004-14001-REA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/SeleccionRequisitos.vue'),
                        },
                        {
                            path: 'gestion-evaluacion-requisitos/:id?',
                            name: 'Gestion evaluacion requisitos 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión evaluación de requisitos", module: { name: "004-14001-REA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/GestionEvaluacionRequisitos.vue'),
                        }, {
                            path: 'requisitos',
                            name: 'Requisitos 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Requisitos", module: { name: "004-14001-REA-REQ", extend: '004-14001-REA' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Requisitos.vue'),
                        }, {
                            path: 'simulacros',
                            name: 'Simulacros',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Simulacros", module: { name: "004-14001-SIM" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/Simulacros.vue'),
                        }, {
                            path: 'comunicaciones-ambientales',
                            name: 'Comunicaciones ambientales',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Comunicaciones ambientales", module: { name: "004-14001-COM" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/ComunicacionesAmbientales.vue'),
                        }, {
                            path: 'inventario-residuos',
                            name: 'Inventario residuos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Inventario residuos", module: { name: "004-14001-INV" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/InventarioResiduos.vue'),
                        }, {
                            path: 'gestion-inicial',
                            name: 'Gestión inicial 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión inicial", module: { name: "004-14001-GEI" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/GestionInicial.vue'),
                        },
                        {
                            path: 'gestion-inicial-informe/:id?',
                            name: 'Gestión inicial informe 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión inicial informe", module: { name: "004-14001-GEI" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/GestionInicialInformes.vue'),
                        },
                        {
                            path: 'documentacion',
                            name: 'Gestión inicial documentacion 14001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Documentacion", module: { name: "004-14001-GEI-DOC", extends: "004-14001-GEI" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/14001/GestionInicialDocumentacion.vue'),
                        },
                    ]
                }, {
                    path: 'seguridad-de-la-informacion',
                    redirect: '/seguridad-de-la-informacion/declaracion-de-aplicabilidad',
                    meta: {
                        isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                        label: "ISO 27001 - Seguridad de la Información",
                        module: {
                            ISOname: "SGSI",
                            name: "005-27001"
                        }
                    },
                    name: 'ISO 27001',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'declaracion-de-aplicabilidad',
                            name: 'Declaración de aplicabilidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Declaración de aplicabilidad", module: { name: "005-27001-DEC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/DeclaracionAplicabilidad.vue'),
                        },
                        /* {
                            path: 'gestion-declaracion-de-aplicabilidad',
                            name: 'Gestión de declaración de aplicabilidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de declaración de aplicabilidad", module: { name: "005-27001-DEC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/GestionDeclaracionAplicabilidad.vue'),
                        }, */
                        {
                            path: 'gestion-declaracion-de-aplicabilidad/:id?',
                            name: 'Gestión de declaración de aplicabilidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de declaración de aplicabilidad", module: { name: "005-27001-DEC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/GestionDeclaracionAplicabilidad.vue'),
                        },
                        {
                            path: 'activos-de-informacion',
                            name: 'Activos de información',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Activos de información", module: { name: "005-27001-ACT" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/ActivosInformacion.vue'),
                        },
                        {
                            path: 'vulnerabilidades',
                            name: 'Vulnerabilidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Vulnerabilidades", module: { name: "005-27001-DEC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/Vulnerabilidades.vue'),
                        },
                        /* {
                            path: 'gestionde-de-vulnerabilidades',
                            name: 'Gestión de vulnerabilidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de vulnerabilidades", module: { name: "005-27001-DEC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/GestionVulnerabilidades.vue'),
                        }, */
                        {
                            path: 'seleccion-activos/:id?',
                            name: 'Selección de activos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de vulnerabilidades", module: { name: "005-27001-VUL" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/SeleccionActivos.vue'),
                        },
                        {
                            path: 'gestion-vulnerabilidades/:id?',
                            name: 'Gestión de vulnerabilidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de vulnerabilidades", module: { name: "005-27001-VUL" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/GestionEvaluacionVulnerabilidades.vue'),
                        },
                        {
                            path: 'politicas',
                            name: 'Políticas',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Políticas", module: { name: "005-27001-POL" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/27001/Politicas.vue'),
                        },
                    ]
                }, {
                    path: 'seguridad-y-salud-laboral',
                    redirect: '/seguridad-y-salud-laboral/informes',
                    meta: {
                        isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                        label: "ISO 45001 - Seguridad y Salud laboral",
                        module: {
                            ISOname: "SST",
                            name: '006-45001'
                        }
                    },
                    name: 'ISO 45001',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'informes',
                            name: 'Informes 45001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Informes", module: { name: "006-45001-INF" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/45001/Informes.vue'),
                        },
                        {
                            path: 'gestion-informe/:id?/',
                            name: 'Gestion de Informes 45001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de informes", module: { name: "006-45001-INF" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/45001/GestionInformes.vue'),
                        },
                        {
                            path: 'documentacion',
                            name: 'Documentacion 45001',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Documentacion", module: { name: "006-45001-INF-DOC", extend: '006-45001-INF' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/45001/Documentacion.vue'),
                        }
                    ]
                }, {
                    path: 'gestion-de-calidad',
                    redirect: '/gestion-de-calidad/aseguramiento-de-calidad',
                    meta: {
                        isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                        label: "ISO 9001",
                        module: {
                            ISOname: "SGC",
                            name: '007-9001'
                        }
                    },
                    name: 'ISO 9001',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'aseguramiento-de-calidad',
                            name: 'Aseguramiento de calidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Aseguramiento de calidad", module: { name: "007-9001-ASE" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/9001/AseguramientoCalidad.vue'),
                        },
                        /* {
                            path: 'gestion-aseguramiento-de-calidad',
                            name: 'Gestión aseguramiento de calidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de aseguramiento de calidad", module: { name: "007-9001-ASE" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/9001/GestionAseguramientoCalidad.vue'),
                        }, */
                        {
                            path: 'gestion-aseguramiento-de-calidad/:id?',
                            name: 'Gestión aseguramiento de calidad',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de aseguramiento de calidad", module: { name: "007-9001-ASE" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/9001/GestionAseguramientoCalidad.vue'),
                        },
                    ]
                },
                , {
                    path: 'gestion-antisoborno',
                    redirect: '/gestion-antisoborno/publicacion-politicas',
                    meta: {
                        isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                        label: "ISO 37001",
                        module: {
                            ISOname: "SGA",
                            name: '017-37001'
                        }
                    },
                    name: 'ISO 37001',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'politicas-normas-codigos-antisoborno',
                            name: 'Políticas, normas y códigos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Políticas, normas y códigos antisoborno", module: { name: "017-37001-PPO" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/PublicacionPoliticasNormasCodigos.vue'),
                        },
                        {
                            path: 'socios-de-negocio',
                            name: 'Socios de negocio',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Socios de negocio", module: { name: "017-37001-SON" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/SociosNegocio.vue'),
                        },
                        {
                            path: 'evaluacion-de-riesgos-antisoborno',
                            name: 'Evaluación de riesgos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Evaluación de riesgos antisoborno", module: { name: "017-37001-ERA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/EvaluacionRiesgosAntisoborno.vue'),
                        },
                        {
                            path: 'ver-evaluacion-de-riesgos-antisoborno/:id',
                            name: 'Ver evaluaciones de riesgos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Ver evaluaciones de riesgos antisoborno", module: { name: "017-37001-ERA-VER" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/VerEvaluacionesRiesgos.vue'),
                        },
                        {
                            path: 'seleccion-riesgos-antisoborno/:id?',
                            name: 'Selección riesgos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Selección riesgos antisoborno", module: { name: "017-37001-ERA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/SeleccionRiesgosAntisoborno.vue'),
                        },
                        {
                            path: 'gestion-evaluacion-riesgos-antisoborno/:id?',
                            name: 'Gestión evaluación riesgos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión evaluación riesgos antisoborno", module: { name: "017-37001-ERA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/GestionEvaluacionRiesgos.vue'),
                        },
                        {
                            path: 'riesgos-antisoborno',
                            name: 'Riesgos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Riesgos antisoborno", module: { name: "017-37001-ERA-RIA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/RiesgosAntisoborno.vue'),
                        },
                        {
                            path: 'controles-antisoborno',
                            name: 'Controles antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Controles antisoborno", module: { name: "017-37001-COA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/ControlesAntisoborno.vue'),
                        },
                        {
                            path: 'inquietudes-antisoborno',
                            name: 'Inquietudes antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Inquietudes antisoborno", module: { name: "017-37001-IQA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/InquietudesAntisoborno.vue'),
                        },
                        {
                            path: 'gestion-y-seguimiento-de-casos-antisoborno',
                            name: 'Gestión y seguimiento de casos antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión y seguimiento de casos antisoborno", module: { name: "017-37001-GSA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/GestionSeguimiento.vue'),
                        },
                        {
                            path: 'actas-funcion-de-cumplimiento',
                            name: 'Actas función de cumplimiento',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Actas función de cumplimiento", module: { name: "017-37001-AFC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/ActasFuncionCumplimiento.vue'),
                        },
                        {
                            path: 'delegacion-de-toma-de-decisiones',
                            name: 'Delegación de toma de decisiones',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Delegación de toma de decisiones", module: { name: "017-37001-DTD" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/DelegacionTomaDecisiones.vue'),
                        },
                        {
                            path: 'puestos-sensibles-antisoborno',
                            name: 'Puestos sensibles antisoborno',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Puestos sensibles antisoborno", module: { name: "017-37001-PSA" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/37001/PuestosSensiblesAntisoborno.vue'),
                        },
                    ]
                }, {
                    path: 'proyectos',
                    redirect: '/proyectos/lista-de-proyectos',
                    meta: {
                        isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                        label: "Proyectos",
                        module: {
                            name: '008-PRO'
                        }
                    },
                    name: 'Proyectos',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'lista-de-proyectos',
                        name: 'Lista de Proyectos',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Lista de Proyectos", module: { name: "008-PRO-PRO" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Proyectos/Proyectos.vue'),
                    }, {
                        path: 'clientes',
                        name: 'Lista de Clientes',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Lista de Clientes", module: { name: "008-PRO-CLI" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Proyectos/Clientes.vue'),
                    }, {
                        path: 'lote-de-productos',
                        name: 'Lote de productos',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Lote de productos", module: { name: "008-PRO-LDP" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Proyectos/LoteProductos.vue'),
                    }, {
                        path: 'servicios',
                        name: 'Servicios coordinador',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Servicios", module: { name: "008-PRO-SER" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Proyectos/Servicios.vue'),
                    }]
                },
                {
                    path: 'recursos',
                    redirect: '/recursos/proveedores',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Recursos", module: { name: "009-REC" } },
                    name: 'Recursos',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'proveedores',
                            name: 'Proveedores',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Proveedores", module: { name: "009-REC-PRO" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/Proveedores.vue'),
                        },
                        {
                            path: 'gestion-proveedor/:id?',
                            name: 'Gestion Proveedor',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión Proveedor", module: { name: "009-REC-PRO" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/GestionProveedor.vue'),
                        },
                        {
                            path: 'incidencias-proveedor',
                            name: 'Incidencias Proveedor',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Incidencias Proveedor", module: { name: "009-REC-PRO-INC", extend: "009-REC-PRO" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/Incidencias.vue'),
                        },
                        {
                            path: 'evaluaciones-proveedor',
                            name: 'Evaluaciones Proveedor',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Evaluaciones Proveedor", module: { name: "009-REC-PRO-CUE", extend: '009-REC-PRO' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/Evaluaciones.vue'),
                        },
                        {
                            path: 'configuracion-evaluacion',
                            name: 'Configuracion Evaluacion',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Configuración Evaluación", module: { name: "009-REC-PRO-PRE", extend: '009-REC-PRO' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/ConfiguracionEvaluacion.vue'),
                        },
                        {
                            path: 'gestion-evaluacion-proveedores/:id',
                            name: 'Gestion Evaluacion Proveedores',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión evaluación de proveedores", module: { name: "009-REC-PRO-CUE", extend: '009-REC-PRO' } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/GestionEvaluacion.vue'),
                        },
                        {
                            path: 'equipos-de-medicion',
                            name: 'Equipos Medicion',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Equipos de Medición", module: { name: "009-REC-EQU" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/EquiposMedicion.vue'),
                        },
                        {
                            path: 'infraestructura',
                            name: 'Infraestructura',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: 'Infraestructura', module: { name: "009-REC-INF" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/Infraestructura.vue'),
                        }, {
                            path: 'recursos-humanos',
                            name: 'Recursos humanos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: 'Recursos humanos', module: { name: "009-REC-REC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/RecursosHumanos.vue'),
                        }, {
                            path: 'puestos-trabajo',
                            name: 'Puestos de trabajo',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: 'Puestos de trabajo', module: { name: "009-REC-REC-PUE", extend: "009-REC-REC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/PuestosTrabajo.vue'),
                        }, {
                            path: 'planes-de-formacion',
                            name: 'Planes de formación',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: 'Planes de formación', module: { name: "009-REC-REC-PLA", extend: "009-REC-REC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Recursos/PlanesFormacion.vue'),
                        }
                    ]
                },
                {
                    path: 'mejora-continua',
                    redirect: '/mejora-continua/otros-documentos',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Mejora contínua", module: { name: "010-MEJ" } },
                    name: 'Mejora continua',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'revision-por-la-direccion',
                            name: 'Revisión por la dirección',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Revisión por la dirección", module: { name: "010-MEJ-REV" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/RevisionDireccion.vue'),
                        },
                        /* {
                            path: 'gestion-revision',
                            name: 'Gestión de revisión por la dirección',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de revisión por la dirección", module: { name: "010-MEJ-REV" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/GestionRevision.vue'),
                        }, */
                        {
                            path: 'gestion-revision/:id?',
                            name: 'Gestión de revisión por la dirección',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de revisión por la dirección", module: { name: "010-MEJ-REV" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/GestionRevision.vue'),
                        },
                        {
                            path: 'otros-documentos',
                            name: 'Otros Documentos',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Otros Documentos", module: { name: "010-MEJ-OTR" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/OtrosDocumentos.vue'),
                        },
                        {
                            path: 'satisfaccion-del-cliente',
                            name: 'Satisfaccion Cliente',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Satisfaccion de Clientes", module: { name: "010-MEJ-SAT" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/SatisfaccionCliente.vue'),
                        },
                        {
                            path: 'configuracion-satisfaccion',
                            name: 'Configuracion Satisfaccion',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Configuración Satisfacción", module: { name: "010-MEJ-SAT-CON", extend: "010-MEJ-SAT" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/ConfiguracionSatisfaccion.vue'),
                        },
                        {
                            path: 'gestion-satisfaccion-cliente/:id/',
                            name: 'Gestion Satisfaccion Clientes',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión satisfacción de clientes", module: { name: "010-MEJ-SAT" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/GestionSatisfaccion.vue'),
                        },
                        {
                            path: 'auditoria-interna',
                            name: 'Auditoría interna',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Auditoría interna", module: { name: "010-MEJ-AUD" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/AuditoriaInterna.vue'),
                        },

                        {
                            path: 'gestion-auditoria/:id?',
                            name: 'Gestión Auditoría interna',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión Auditoría interna", module: { name: "010-MEJ-AUD" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/GestionAuditoria.vue'),
                        },
                        {
                            path: 'normas',
                            name: 'Normas',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Normas", module: { name: "010-MEJ-AUD-NOR", extend: "010-MEJ-AUD" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/Normas.vue'),
                        },
                        {
                            path: 'gestion-normas/:id?',
                            name: 'Gestión de normas',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de normas", module: { name: "010-MEJ-AUD-NOR", extend: "010-MEJ-AUD" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/MejoraContinua/GestionNormas.vue'),
                        },


                    ]
                },
                {
                    path: 'incidentes',
                    redirect: '/incidentes/incidencias',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Incidentes", module: { name: "011-INC" } },
                    name: 'Incidentes',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'incidencias',
                            name: 'Incidencias',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Incidencias", module: { name: "011-INC-INC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/Incidencias.vue'),
                        },
                        {
                            path: 'gestion-de-incidencias/:id?',
                            name: 'Gestion de incidencias',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de Incidencias", module: { name: "011-INC-INC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/GestionIncidencia.vue'),
                        },
                        {
                            path: 'informe-global-incidencias',
                            name: 'Informe Global Incidencias',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Informe global de Incidencias", module: { name: "011-INC-INC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/InformeGlobalIncidencias.vue'),
                        },
                        {
                            path: 'no-conformidades',
                            name: 'No Conformidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "No Conformidades", module: { name: "011-INC-NOC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/NoConformidades.vue'),
                        }, {
                            path: 'gestion-de-no-conformidades/:id?/',
                            name: 'Gestion de no Conformidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Gestión de no conformidades", module: { name: "011-INC-NOC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/GestionNoConformidades.vue'),
                        }, {
                            path: 'informe-global-no-conformidades',
                            name: 'Informe Global no conformidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Informe global de No conformidades", module: { name: "011-INC-NOC" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/InformeGlobalNoConformidades.vue'),
                        },
                        {
                            path: 'categorias-de-las-no-conformidades',
                            name: 'Categorías de las no conformidades',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Categorías de las no conformidades", module: { name: "011-INC-CAT" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/CategoriasNoConformidades.vue'),
                        },
                        {
                            path: 'incidencias-de-software',
                            name: 'Incidencias de software',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Incidencias de software", module: { name: "011-INC-INS" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/IncidenciasSoftware.vue'),
                        },
                        {
                            path: 'incidencias-de-software-finalizadas',
                            name: 'Incidencias de software finalizadas',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Incidencias de software finalizadas", module: { name: "011-INC-INS" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Incidentes/IncidenciasSoftwareFinalizadas.vue'),
                        }
                    ]
                },
                {
                    path: 'usuarios',
                    redirect: '/usuarios/lista-de-usuarios',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Usuarios", module: { name: "012-USU" } },
                    beforeEnter: checkPermissionsModule,
                    name: 'Usuarios',

                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'lista-de-usuarios',
                            name: 'Lista de Usuarios',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Lista de Usuarios", module: { name: "012-USU-LIS" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Usuarios/ListaUsuarios.vue'),
                        },
                        {
                            path: 'perfiles',
                            name: 'Perfiles',
                            meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Perfiles", module: { name: "012-USU-PER" } },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Usuarios/Perfiles.vue'),
                        },
                        {
                            path: 'gestion-de-perfiles/:id?',
                            name: 'Gestión de Perfiles',
                            meta: {
                                isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef",
                                label: 'Gestión de Perfiles',
                                module: { name: "012-USU-PER" }
                            },
                            beforeEnter: checkPermissionsSubModule,
                            component: () =>
                                import ('@/views/Coordinador/Usuarios/GestionPerfil.vue')
                        },
                    ]
                },
                {
                    path: 'tareas',
                    redirect: '/tareas/calendario',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Tareas", module: { name: "013-TAR" } },
                    name: 'Tareas',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'calendario',
                        name: 'Calendario',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Calendario", module: { name: "013-TAR-CAL" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Tareas/Calendario.vue'),
                    }]
                },
                {
                    path: 'correos',
                    redirect: '/correos/control-de-correos',
                    meta: { label: "Correos", module: { name: "014-EMA" } },
                    name: 'Correos',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'control-de-correos',
                        name: 'Control de correos',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Control de correos", module: { name: "014-EMA-CON" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/Correos/ControlCorreos.vue'),
                    }]
                },
                {
                    path: 'control-de-horarios',
                    redirect: '/control-de-horarios/control-de-horario-productividad',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Control de horarios", module: { name: "015-CON" } },
                    name: 'Control Horarios',
                    beforeEnter: checkPermissionsModule,
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'control-de-horario-productividad',
                        name: 'Control de Horario Productividad',
                        meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Control de horarios y productividad", module: { name: "015-CON-HOR" } },
                        beforeEnter: checkPermissionsSubModule,
                        component: () =>
                            import ('@/views/Coordinador/ControlHorarios/ControlHorario.vue'),
                    }]
                },
                {
                    path: 'panel-de-control',
                    name: 'Panel de Control',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Panel de control", },
                    component: () =>
                        import ('@/views/Coordinador/PanelControl.vue'),
                }, {
                    path: 'perfil',
                    name: 'Perfil Coordinador',
                    meta: { isTipo: "c2c4ef67-2198-40a3-9c31-adf97d4b88ef", label: "Perfil" },
                    component: () =>
                        import ('@/views/Paginas/Perfil.vue'),
                },
            ]
        },

        {
            path: '/',
            component: ContentCoordinadorCuestionariosLayout,
            children: [{
                path: 'cuestionarios',
                redirect: '/cuestionarios/evaluacion-del-proveedor',
                meta: { label: "Cuestionarios" },
                component: {
                    render(c) { return c('router-view') }
                },
                children: [{
                        path: 'evaluacion-del-proveedor',
                        name: 'Vista Evaluacion del proveedor',
                        meta: { label: "Evaluación del proveedor", subTitle: "CUESTIONARIO ONLINE DE EVALUACIÓN DE PROVEEDORES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoEvaluacionProveedor.vue'),
                    }, {
                        path: 'evaluacion-del-proveedor/:token/:id',
                        name: 'Evaluacion del proveedor',
                        meta: { label: "Evaluación del proveedor", subTitle: "CUESTIONARIO ONLINE DE EVALUACIÓN DE PROVEEDORES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoEvaluacionProveedor.vue'),
                    },
                    {
                        path: 'satisfaccion-del-cliente',
                        name: 'Vista Satisfaccion del cliente',
                        meta: { label: "Satisfacción del cliente", subTitle: "CUESTIONARIO ONLINE DE SATISFACCIÓN DE CLIENTES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoSatisfaccionCliente.vue'),
                    }, {
                        path: 'satisfaccion-del-cliente/:token/:id',
                        name: 'Satisfaccion del cliente',
                        meta: { label: "Satisfacción del cliente", subTitle: "CUESTIONARIO ONLINE DE SATISFACCIÓN DE CLIENTES" },
                        component: () =>
                            import ('@/views/Paginas/ContenidoSatisfaccionCliente.vue'),
                    }
                ]
            }]
        },
        {
            path: '/',

            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '404',
                    name: 'Pagina404',
                    component: () =>
                        import ('@/views/Paginas/404.vue')
                },
                {
                    path: '500',
                    name: 'Pagina500',
                    component: () =>
                        import ('@/views/Paginas/500.vue')
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: () =>
                        import ( /* webpackChunkName: "Login" */ '@/views/Paginas/Login.vue')
                },
                {
                    path: ':rsCliente/login',
                    name: 'Login Cliente',
                    component: () =>
                        import ( /* webpackChunkName: "Login Cliente" */ '@/views/Paginas/LoginCliente.vue')
                },
                {
                    path: 'recuperar',
                    name: 'Recuperar',
                    component: () =>
                        import ('@/views/Paginas/Recuperar.vue')
                },
                {
                    path: '*',
                    name: '404',
                    component: () =>
                        import ('@/views/Paginas/404.vue')
                },
            ]
        }
    ]
}

export default router;